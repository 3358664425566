var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "topTitle paddingL20 paddingT10" },
      [
        _c(
          "el-col",
          { attrs: { span: 12 } },
          [_c("titleModule", { attrs: { title: "素材详情" } })],
          1
        ),
        _c("el-col", { attrs: { span: 12 } }, [
          _c(
            "div",
            { staticClass: "handleBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Wrapper paddingL20 paddingB10" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              "label-position": "left",
              "label-width": "100px",
              model: _vm.formInline,
            },
          },
          [
            _c("el-form-item", { attrs: { label: "名称:" } }, [
              _c("span", [_vm._v(_vm._s(_vm.formInline.materialName))]),
            ]),
            _c("el-form-item", { attrs: { label: "播放时长:" } }, [
              _c("span", [_vm._v(_vm._s(_vm.formInline.playTime) + "秒")]),
            ]),
            _c("el-form-item", { attrs: { label: "屏幕尺寸:" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.formInline.screenWidth) +
                    "*" +
                    _vm._s(_vm.formInline.screenHeight)
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "screen",
                  style: {
                    width: _vm.formInline.screenWidth + "px",
                    height: _vm.formInline.screenHeight + "px",
                  },
                },
                [
                  _vm.formInline.materialType === 1
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.formInline.berthList,
                          function (item, index) {
                            return _c("div", {
                              key: index,
                              staticClass: "berth",
                              style: {
                                top: item.textY + "px",
                                left: item.textX + "px",
                                fontSize: item.textFontSize + "px",
                              },
                            })
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.formInline.materialType === 1 ||
                  _vm.formInline.materialType === 2 ||
                  _vm.formInline.materialType === 10
                    ? _c(
                        "div",
                        {
                          staticClass: "imgPreview",
                          style: {
                            left: _vm.formInline.mediaX + "px",
                            top: _vm.formInline.mediaY + "px",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.mediaResourceUrl,
                              width: _vm.formInline.mediaShowWidth,
                              height: _vm.formInline.mediaShowHeight,
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.formInline.materialType === 4 ||
                  _vm.formInline.materialType === 12
                    ? _c(
                        "div",
                        {
                          staticClass: "imgPreview",
                          style: {
                            left: _vm.formInline.mediaX + "px",
                            top: _vm.formInline.mediaY + "px",
                          },
                        },
                        [
                          _c("video", {
                            attrs: {
                              src: _vm.mediaResourceUrl,
                              width: _vm.formInline.mediaShowWidth,
                              height: _vm.formInline.mediaShowHeight,
                              controls: "",
                              autoplay: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.formInline.materialType === 8 ||
                  _vm.formInline.materialType === 12 ||
                  _vm.formInline.materialType === 10
                    ? _c(
                        "div",
                        {
                          staticClass: "text",
                          style: {
                            left: _vm.formInline.textX + "px",
                            top: _vm.formInline.textY + "px",
                            width: _vm.formInline.textShowWidth + "px",
                            height: _vm.formInline.textShowHeight + "px",
                            lineHeight: 1,
                            fontFamily: _vm.textFont,
                            fontSize: _vm.formInline.textFontSize + "px",
                            color: _vm.styleFontColor,
                            textAlign: _vm.styleTextAlign,
                            fontWeight:
                              _vm.formInline.textFontStyle === 1 ? 600 : 400,
                            fontStyle:
                              _vm.formInline.textFontStyle === 2
                                ? "italic"
                                : "normal",
                            textDecoration:
                              _vm.formInline.textFontStyle === 4
                                ? "underline"
                                : "normal",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.formInline.textContent) + " ")]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }