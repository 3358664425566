var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasIcon,
            expression: "hasIcon",
          },
        ],
        staticClass: "title_icon",
      }),
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }